.dark {
  background-color: rgb(94, 94, 94);

  h1,
  spam {
    color: honeydew;
  }
}

main.dark {
  background-color: rgb(19, 19, 19);
}
