
@media screen and (min-width: 800px) {
    .main-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      row-gap: 20px;
      padding: 10px 0;
    }
  }

  @media screen and (min-width: 1200px) {
      .main-content {
          grid-template-columns: repeat(4, 1fr);
      }
  }