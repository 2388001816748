@import url(https://fonts.googleapis.com/css2?family=Limelight&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
*{box-sizing:border-box}body{margin:0;font-family:"Limelight", cursive;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.dark{background-color:#5e5e5e}.dark h1,.dark spam{color:honeydew}main.dark{background-color:#131313}

.footer{height:80px;box-shadow:0 -5px 10px grey;text-align:center;padding:20px 0}

.header{box-shadow:0 5px 10px #d8d8d8;height:100px;text-align:center}.header .title{padding-top:30px}.header .title h1{margin:0;font-size:2em}.header .toggle{visibility:hidden}.header .theme{width:90px;border:1px solid grey;border-radius:5px;position:relative;left:92%;box-shadow:inset 0 0 10px 2px grey;padding:3px;-webkit-transform:translate(-80%, -20%);transform:translate(-80%, -20%)}.header .theme .label{box-shadow:inset 0 -5px 10px 1px grey;width:45px;border:1px solid grey;border-radius:5px;position:absolute;left:2px;text-align:center;transition:all 1s;padding:0 4px}.header .theme .toggle:checked+label{left:40px;color:white}

.details{font-family:"Indie Flower", cursive;width:auto;border-radius:20px;background:#e0e0e0;box-shadow:9px 9px 10px rgba(136,136,136,0.597),-9px -9px 10px rgba(255,255,255,0.499);display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;-webkit-flex-direction:column;flex-direction:column}.details.dark{background:linear-gradient(145deg, #1d1d1d, #131313);box-shadow:4px 4px 10px rgba(85,85,85,0.899),-6px -6px 10px rgba(23,33,33,0.397);color:#e0e0e0}.details .char-name{font-family:"Limelight", cursive;font-size:18px}.details .alive{font-weight:bold;color:green}.details .dead{font-weight:bold;color:red}.details .unknown{font-weight:bold}

.char-container{background:#e0e0e0;width:250px;display:block;overflow:hidden;border-radius:15px;box-shadow:9px 9px 10px rgba(136,136,136,0.597),-9px -9px 10px rgba(255,255,255,0.499);margin-bottom:20px;padding:10px}.char-container:hover{background:rgba(194,152,231,0.3);box-shadow:inset 0 0 15px 10px #b3b3b3;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.char-container:hover .details{box-shadow:none}.char-container__header{width:50%;margin-left:auto;margin-right:auto}.char-container__header img{width:100%;height:100%;object-fit:cover;border-radius:10px}.char-container.dark{background:#1d1d1d;box-shadow:7px 7px 25px rgba(53,53,53,0.397),-7px -7px 25px rgba(110,110,110,0.699)}

.main-content{background-color:#e0e0e0;display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;-webkit-align-items:center;align-items:center;padding:20px 0}@media screen and (min-width: 800px){.main-content{display:grid;grid-template-columns:repeat(2, 1fr);justify-items:center;grid-row-gap:20px;row-gap:20px;padding:10px 0}}@media screen and (min-width: 1200px){.main-content{grid-template-columns:repeat(4, 1fr)}}

