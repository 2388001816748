@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

.details {
  font-family: "Indie Flower", cursive;
  width: auto;
  border-radius: 20px;
  background: #e0e0e0;
  box-shadow: 9px 9px 10px rgba(136, 136, 136, 0.597),
  -9px -9px 10px rgba(255, 255, 255, 0.499);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.dark {
    background: linear-gradient(145deg, rgb(29, 29, 29), rgb(19, 19, 19));
    box-shadow: 4px 4px 10px rgba(85, 85, 85, 0.899),
    -6px -6px 10px rgba(23, 33, 33, 0.397);
    color: #e0e0e0;
  }
  .char-name {
    font-family: "Limelight", cursive;
    font-size: 18px;
  }

  .alive {
    font-weight: bold;
    color: green;
  }

  .dead {
    font-weight: bold;
    color: red;
  }

  .unknown {
    font-weight: bold;
  }

}
