.char-container {
  background: #e0e0e0;
  width: 250px;
  display: block;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 9px 9px 10px rgba(136, 136, 136, 0.597),
  -9px -9px 10px rgba(255, 255, 255, 0.499);
  margin-bottom: 20px;
  padding: 10px;

  &:hover {
    background: rgba(194, 152, 231, 0.3);
    box-shadow: inset 0 0 15px 10px rgb(179, 179, 179);
    backdrop-filter: blur(5px);
  }

  &:hover .details {
    box-shadow: none;
  }

  &__header {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  &__header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.char-container.dark {
  background: rgb(29, 29, 29);
  box-shadow: 7px 7px 25px rgba(53, 53, 53, 0.397),
  -7px -7px 25px rgba(110, 110, 110, 0.699);
}
