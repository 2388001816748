.header {
  box-shadow: 0 5px 10px #d8d8d8;
  height: 100px;
  text-align: center;

  .title {
    padding-top: 30px;

    h1 {
      margin: 0;
      font-size: 2em;
    }
  }

  .toggle {
    visibility: hidden;
  }
  .theme {
    width: 90px;
    border: 1px solid grey;
    border-radius: 5px;
    position: relative;
    left: 92%;
    box-shadow: inset 0 0 10px 2px grey;
    padding: 3px;
    transform: translate(-80%, -20%);

    .label {
      box-shadow: inset 0 -5px 10px 1px grey;
      width: 45px;
      border: 1px solid grey;
      border-radius: 5px;
      position: absolute;
      left: 2px;
      text-align: center;
      transition: all 1s;
      padding: 0 4px;
    }

    .toggle:checked + label {
      left: 40px;
      color: white;
    }
  }
}
